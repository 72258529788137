import Agents from "./pages/Agents/Agents";
import Dashboard from "./pages/Dashboard/Dashboard";
import AccountAgents from "./pages/AccountAgent/AccountAgent";
import {
    AccountBalanceOutlined, AccountBalanceWallet, AccountBoxOutlined,
    AccountCircle,
    AddCircle, AttachMoney,
    BarChart,
    CardGiftcardOutlined, CardGiftcardRounded,
    CardMembershipOutlined,
    CloudDownloadOutlined,
    CloudUploadOutlined,
    CreditCardOutlined,
    DashboardOutlined, GamepadOutlined, GamesOutlined,
    GifOutlined,
    GifTwoTone, HistoryOutlined,
    HomeOutlined, Money, MoneyOff, MoneyOffOutlined, MoneyOutlined,
    People,
    PeopleAltOutlined, ShareOutlined,
    SupervisedUserCircle,
    SupervisedUserCircleSharp,
    VerifiedUserOutlined, VideogameAsset,
} from "@material-ui/icons";
import Operator from "./pages/Operator/Operator";
import User from "./pages/Users/User";
import UserReport from "./pages/Report/User";
import Promotion from "./pages/Promotion/Promotion";
import TransactionDeposit from "./pages/Transaction/TransactionDeposit";
import TransactionWithdraw from "./pages/Transaction/TransactionWithdraw";
import GameHistory from "./pages/Report/GameHistory";
import Sharing from "./pages/Report/Sharing";
import Bonus from "./pages/Report/Bonus";
import FirstDeposit from "./pages/Report/FirstDeposit";
import Transaction from "./pages/Transaction/Transaction";
import Aff from "./pages/Report/Aff";

const routes = {
    items: [
        {
            path: "/",
            name: "แดชบอร์ด ",
            type: "link",
            icon: HomeOutlined,
            key: 'dashboard',
            component: Dashboard,
            role: ['admin', 'agent', 'operator'],
        },
        {
            name: "ฟังชั่น",
            type: "header",
            role: ['admin', 'agent', 'operator'],
        },
        {
            path: "/agent",
            name: "เอเยนต์",
            type: "link",
            icon: AccountCircle,
            component: Agents,
            role: ['admin', 'agent'],
        },
        {
            path: "/account-agent",
            name: "บัญชีธนาคาร",
            type: "link",
            icon: AccountBalanceOutlined,
            component: AccountAgents,
            role: ['agent'],
        },
        {
            path: "/promtoion-agent",
            name: "โปรโมชั่น",
            type: "link",
            icon: CardGiftcardOutlined,
            component: Promotion,
            role: ['agent', 'operator'],
        },
        {
            path: "/operator",
            name: "พนักงาน",
            type: "link",
            icon: SupervisedUserCircleSharp,
            component: Operator,
            role: ['agent'],
        },
        {
            path: "/user",
            name: "สมาชิก",
            type: "link",
            icon: SupervisedUserCircle,
            component: User,
            role: ['agent', 'operator'],
        },
        {
            name: "รายการเงิน เข้า-ออก",
            type: "header",
            role: ['agent', 'operator'],
        },
        {
            path: "/transactions",
            name: "รายการธุระกรรมการเข้า-ออก",
            type: "link",
            icon: Money,
            key: 'trasaction',
            component: Transaction,
            role: ['agent', 'operator'],
        },
        {
            path: "/deposit",
            name: "รายการธุระกรรมการเติมเงิน",
            type: "link",
            icon: CloudDownloadOutlined,
            key: 'deposit',
            component: TransactionDeposit,
            role: ['agent', 'operator'],
        },
        {
            path: "/withdraw",
            name: "รายการธุระกรรมการถอนเงิน",
            type: "link",
            icon: CloudUploadOutlined,
            key: 'withdraw',
            component: TransactionWithdraw,
            role: ['agent', 'operator'],
        },

        {
            name: "รายงาน",
            type: "header",
            role: ['admin', 'agent', 'operator'],
        },
        {
            path: "/report/sharing",
            name: "รายงานส่วนแบ่ง",
            type: "link",
            icon: ShareOutlined,
            component: Sharing,
            role: ['agent', 'operator'],
        },
        {
            path: "/report/gamehistory",
            name: "รายงานการวางเดิมพัน",
            type: "link",
            icon: HistoryOutlined,
            component: GameHistory,
            role: ['agent', 'operator'],
        },
        {
            path: "/report/affiliate",
            name: "รายงาน Affiliate",
            type: "link",
            icon: HistoryOutlined,
            component: Aff,
            role: ['admin'],
        },
        {
            path: "/report/firstDeposit",
            name: "รายงานฝากแรก",
            type: "link",
            icon: AttachMoney,
            component: FirstDeposit,
            role: ['agent', 'operator'],
        },
        {
            path: "/deposit?report=true",
            name: "รายงานเติมเงิน",
            type: "link",
            icon: CloudDownloadOutlined,
            // key: 'deposit',
            report: true,
            component: TransactionDeposit,
            role: ['agent', 'operator'],
        },
        {
            path: "/withdraw?report=true",
            name: "รายงานถอนเงิน",
            type: "link",
            icon: CloudUploadOutlined,
            // key: 'withdraw',
            report: true,
            component: TransactionWithdraw,
            role: ['agent', 'operator'],
        },
        {
            path: "/report/newUsers",
            name: "รายงานลูกค้าใหม่",
            type: "link",
            icon: AccountBoxOutlined,
            component: UserReport,
            role: ['agent', 'operator'],
        },
        // {
        //     path: "/report/bonus",
        //     name: "รายงานการรับโบนัส",
        //     type: "link",
        //     icon: CardGiftcardRounded,
        //     component: Bonus,
        //     role: ['admin', 'agent', 'operator'],
        // }
    ],
};

export default routes;
