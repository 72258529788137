import {
    createSlice
} from "@reduxjs/toolkit";
import {
    GetTransactionDeposit,
    GetTransactionWithdraw,
    ConfirmDeposit,
    ConfirmWithdraw, CancelDeposit, GetDepositTotal, GetWithdrawTotal, CancelWithdraw, GetAllTransaction
} from "../../api/transaction";
import {NotificationManager} from "react-light-notifications";
import GlobalSlice from "./Global";


const initTransactionState = {
    transactionList: {
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 15,
        total: 0,
        data: []
    },
    depositList: {
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 15,
        total: 0,
        data: []
    },
    withdrawList: {
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 15,
        total: 0,
        data: []
    },
    depositPendingAmount: 0,
    withdrawPendingAmount: 0,
    depositTotal: {
        totalTxn: 0,
        total: 0,
        subTotal: 0,
        totalBonus: 0,
        loaded: false
    },
    withdrawTotal: {
        totalTxn: 0,
        total: 0,
        loaded: false
    }
};

const TransactionSlice = createSlice({
    name: "TransactionSlice",
    initialState: initTransactionState,
    reducers: {
        getTransaction: (state, action) => {
            const {
                data
            } = action.payload;

            data.data = data.data ? data.data : []

            return Object.assign(state, {
                transactionList: data,
            });
        },
        getDeposit: (state, action) => {
            const {
                data
            } = action.payload;

            data.data = data.data ? data.data : []

            return Object.assign(state, {
                depositList: data,
            });
        },
        getWithdraw: (state, action) => {
            const {
                data
            } = action.payload;
            data.data = data.data ? data.data : []
            return Object.assign(state, {
                withdrawList: data,
            });
        },
        setDepositPending: (state, action) => {
            const {
                data
            } = action.payload;
            data.data = data.data ? data.data : []
            return Object.assign(state, {
                depositPendingAmount: data.total,
            });
        },
        setWithdrawPending: (state, action) => {
            const {
                data
            } = action.payload;
            data.data = data.data ? data.data : []
            return Object.assign(state, {
                withdrawPendingAmount: data.total,
            });
        },
        getDepositTotal: (state, action) => {
            const {
                data
            } = action.payload;
            return Object.assign(state, {
                depositTotal: {...data, loaded: true},
            });
        },
        getWithdrawTotal: (state, action) => {
            const {
                data
            } = action.payload;
            return Object.assign(state, {
                withdrawTotal: {...data, loaded: true},
            });
        },
    },
});
export default TransactionSlice;

export const fetchDepositTransaction = (_page, _pageSize = 15, _from, _to, _status, firstTime = false, username = ``) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await GetTransactionDeposit(_page, _pageSize, _from, _to, _status, firstTime, username);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                dispatch(TransactionSlice.actions.getDeposit({
                    data: response.data
                }));
                if (_status == "pending") {
                    dispatch(TransactionSlice.actions.setDepositPending({
                        data: response.data
                    }));
                }
            }
            dispatch(GlobalSlice.actions.setOnSuccess())
        } catch (e) {
            console.log(e);
            dispatch(GlobalSlice.actions.setOnSuccess())
        }
    };
};

export const fetchDepositePendingCount = () => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await GetTransactionDeposit(1, 100, '', '', 'failed,pending');
            dispatch(GlobalSlice.actions.setOnSuccess())
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }
            if (response.status === 200) {
                dispatch(TransactionSlice.actions.setDepositPending({
                    data: response.data
                }));
            }

        } catch (e) {
            console.log(e);
            dispatch(GlobalSlice.actions.setOnSuccess())
        }
    }
}
export const fetchWithdrawTransaction = (_page, _pageSize = 15, _from, _to, _status, username = ``) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await GetTransactionWithdraw(_page, _pageSize, _from, _to, _status, username);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                dispatch(TransactionSlice.actions.getWithdraw({
                    data: response.data
                }));

                if (_status == "pending") {
                    dispatch(TransactionSlice.actions.setWithdrawPending({
                        data: response.data
                    }));
                }
            }
        } catch (e) {
            console.log(e);
        }
        dispatch(GlobalSlice.actions.setOnSuccess())
    };
};

export const fetchWithdrawPendingCount = () => {
    return async (dispatch) => {
        try {
            const response = await GetTransactionWithdraw(1, 100, '', '', 'failed,pending');
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }
            if (response.status === 200) {
                dispatch(TransactionSlice.actions.setWithdrawPending({
                    data: response.data
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export const confirmDeposit = (txnId, model, handleClose, pageNumber, itemPerPage, start, end) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await ConfirmDeposit(txnId, model);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                NotificationManager.success({
                    title: "Success",
                    message: "Deposit Success",
                });
                if (pageNumber > 1) {
                    dispatch(fetchDepositTransaction(pageNumber, itemPerPage, start, end));
                } else {
                    dispatch(fetchDepositTransaction(1, itemPerPage, start, end));
                }

                handleClose();
            }
        } catch (e) {
            NotificationManager.error({
                title: "Error",
                message: "Deposit Error",
            });
            console.log(e);
        }
        dispatch(GlobalSlice.actions.setOnSuccess())
    };
};

export const confirmWithdraw = (txnId, handleClose, pageNumber, itemPerPage, start, end) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await ConfirmWithdraw(txnId);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                NotificationManager.success({
                    title: "Success",
                    message: "Withdraw Success",
                });
                if (pageNumber > 1) {
                    dispatch(fetchWithdrawTransaction(pageNumber, itemPerPage, start, end));
                } else {
                    dispatch(fetchWithdrawTransaction(1, itemPerPage, start, end));
                }

                handleClose();
            }
        } catch (e) {
            NotificationManager.error({
                title: "Error",
                message: "Withdraw Error",
            });
            console.log(e);
        }
        dispatch(GlobalSlice.actions.setOnSuccess())
    };
};

export const cancelDeposit = (txnId, pageNumber, itemPerPage, start, end) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await CancelDeposit(txnId);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                NotificationManager.success({
                    title: "Success",
                    message: "Cancel item success",
                });
                if (pageNumber > 1) {
                    dispatch(fetchDepositTransaction(pageNumber, itemPerPage, start, end));
                } else {
                    dispatch(fetchDepositTransaction(1, itemPerPage, start, end));
                }
            }
        } catch (e) {
            NotificationManager.error({
                title: "Error",
                message: "Someting wrong!",
            });
            console.log(e);
        }
        dispatch(GlobalSlice.actions.setOnSuccess())
    };
};

export const cancelWithdraw = (txnId, pageNumber, itemPerPage, start, end) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await CancelWithdraw(txnId);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                NotificationManager.success({
                    title: "Success",
                    message: "Cancel item success",
                });
                if (pageNumber > 1) {
                    dispatch(fetchWithdrawTransaction(pageNumber, itemPerPage, start, end));
                } else {
                    dispatch(fetchWithdrawTransaction(1, itemPerPage, start, end));
                }
            }
        } catch (e) {
            NotificationManager.error({
                title: "Error",
                message: "Someting wrong!",
            });
            console.log(e);
        }
        dispatch(GlobalSlice.actions.setOnSuccess())
    };
};


export const getDepositTotal = (from, to) => {
    return async (dispatch) => {
        dispatch(TransactionSlice.actions.getDepositTotal({
            data: {
                totalTxn: 0,
                total: 0,
                subTotal: 0,
                totalBonus: 0,
            }
        }));
        try {
            const response = await GetDepositTotal(from, to);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }
            if (response.status === 200) {
                dispatch(TransactionSlice.actions.getDepositTotal({
                    data: response.data
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export const getWithdrawTotal = (from, to) => {
    return async (dispatch) => {
        try {
            const response = await GetWithdrawTotal(from, to);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }
            if (response.status === 200) {
                dispatch(TransactionSlice.actions.getWithdrawTotal({
                    data: response.data
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }
}


export const fetchAllTransaction = (filter) => {
    return async (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        try {
            const response = await GetAllTransaction(filter);
            if (!response.status >= 400) {
                throw Error(response.statusText);
            }

            if (response.status === 200) {
                dispatch(TransactionSlice.actions.getTransaction({
                    data: response.data
                }));
            }
            dispatch(GlobalSlice.actions.setOnSuccess())
        } catch (e) {
            console.log(e);
            dispatch(GlobalSlice.actions.setOnSuccess())
        }
    };
};
